import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import AboutPage from './about';
export const pageQuery = graphql`
  query AboutEnQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "about-us.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
export const _frontmatter = {
  "type": "about",
  "langKey": "en",
  "title": "About the Sharp Blades"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = AboutPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Quality of processing and used materials`}</h2>
    <p>{`The Sharp Blades enterprise deals with manufacuring handmade weapons with emphasis on quality of processing and used materials.`}</p>
    <h2>{`Wide range of products`}</h2>
    <p>{`We make a wide range of weapons from Shurikens to damask knifes, daggers and even axes and machetes. In our production you can find that the products are made from the highest grade materials by the masters of the craft with a lot of years of practice and huge amount of experiences.`}</p>
    <h2>{`Skill earned with years of experience`}</h2>
    <p>{`We are skilled in processing metals (iron and also nonferrous) of various grades and properties but also in processing domestic and exotic wood.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      